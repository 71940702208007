import MaterialTable from "@material-table/core";
import { ExportCsv } from "@material-table/exporters";
import { database } from "configs";
import { useAppContext } from "contexts";
import { useFetch } from "hooks";
import moment from "moment";
import Swal from "sweetalert2";
import firebase from "firebase/app";
import "firebase/auth";

const Users = () => {
    const [data] = useFetch("/Users");
    // const { user } = useAppContext();
    // console.log(user,"abc")
    // const tableData = data === null ? [] : data
    // .filter((item) => item?.role === 'multiadmin')
    // .map((item, index) => ({
    //   ...item,
    //   sl: index + 1,
    // }));

    console.log(data)
    const handleDeleteRow = (rowData) => {
        Swal.fire({
            title: "Are you sure you ?",
            text: "You will not be able to recover this user!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire("Deleted!", "The user has been deleted.", "success");
            }
        });
    };

    return (
        <section className="py-5">
            <MaterialTable
                data={
                    data === null
                      ? []
                      : data?.filter((item) => item?.role === 'multiadmin')
                        .map((item, index) => ({
                            ...item,
                            sl: index + 1,
                        }))
                  }
                title="All Admin Users"
                columns={[
                    { title: "#", field: "sl", editable: "never", width: "10%" },
                    { title: "Name", field: "displayName" },
                    { title: "Email", field: "email", export: true },
                    { title: "Password", field: "password", export: true },
                    {
                        title: "Created At",
                        field: "timestamp",
                        emptyValue: "--",
                        editable: "never",
                        render: ({ timestamp }) => moment(new Date(timestamp)).fromNow(),
                    },
                    // {
                    //     title: 'Created At',
                    //     field: 'tim',
                    //     render: ({ updatedAt }) => moment(new Date(updatedAt)).fromNow(),
                    //     editable: 'never',
                    //     emptyValue: '-',
                    //     filtering: false,
                    //   },
                    // {
                    //     title: "Add City",
                    //     field: "cities",
                    //     render: () => (
                    //         <div className="flex gap-1">
                    //             <>
                    //                 <Tooltip title="Select Cities">
                    //                     <div className="cursor-pointer">
                    //                         <ApartmentIcon />
                    //                     </div>
                    //                 </Tooltip>
                    //             </>
                    //         </div>
                    //     ),
                    //     export: true,
                    // },
                ]}
                options={{
                    detailPanelColumnAlignment: "right",
                    exportAllData: true,
                    addRowPosition: "first",
                    headerStyle: {
                        backgroundColor: "#f2f2f2",
                        color: "#333",
                        fontWeight: "bold",
                        fontSize: "14px",
                    },
                    exportMenu: [
                        {
                            label: "Export Users Data In CSV",
                            exportFunc: (cols, data) => ExportCsv(cols, data),
                        },
                    ],
                    actionsColumnIndex: -1,
                }}
                style={{
                    boxShadow: "#6a1b9a3d 0px 8px 16px 0px",
                    borderRadius: "8px",
                }}
                editable={{
                    onRowAdd: async (newData) => {
                        try {
                          const { email, password, ...rest } = newData;
                          const userCredential = await firebase
                            .auth()
                            .createUserWithEmailAndPassword(email, password);
                          const user = userCredential.user;
                          const uid = user.uid;
                      
                          const updatedData = {
                            ...rest,
                            email,
                            password,
                            role: "multiadmin",
                            timestamp: new Date().toString(),
                            updatedAt: new Date().toString(),
                          };
                      
                          await firebase.database().ref(`Users/${uid}`).update(updatedData);
                      
                          Swal.fire('Success!', 'Multi Admin Added Successfully', 'success');
                        } catch (error) {
                          console.log(error);
                      
                          Swal.fire({
                            title: 'Error!',
                            text: error.message,
                            icon: 'error',
                            confirmButtonText: 'OK',
                          });
                        }
                    },
                      onRowUpdate: async (newData, oldData) => {
                        const dbRef = `Users/${oldData?.id}`
                        await database.ref(dbRef).update({
                          ...newData,
                          tableData: {},
                          updatedAt: new Date().toString(),
                        })
                        Swal.fire('Success!', 'Multi Admin Updated Successfully', 'success')
                      },
                      onRowDelete: async (oldData) => {
                        await database.ref(`Users/${oldData.id}`).remove()
                        Swal.fire('Success!', 'Multi Admin Deleted Successfully', 'success')
                      },
                }}
            />
        </section>
    );
};

export default Users;
